<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h4 class="header-title font-weight-bold"> List of Groceries</h4>
                            </div>
                            <div class="col-sm-6">
                                <div class="float-sm-right">
                                    <router-link
                                            to="/grocery/add"
                                            class="btn btn-danger mb-2"
                                    ><i class="mdi mdi-plus-circle mr-1"></i> Add
                                        Groceries</router-link
                                    >
                                </div>
                            </div>
                        </div>

                        <div>
                            <lottie-loader :loading="showLoader" />
                        </div>
                        <p class="text-muted font-13 mb-4"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" id="table">
                            <b-table
                                    table-class="table table-centered w-100"
                                    thead-tr-class="bg-light"
                                    :items="listOfGroceries"
                                    :fields="tableFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(image)="data">
                                    <img
                                            v-if="data.item.image"
                                            :src="data.item.image"
                                            alt=""
                                            class="rounded mr-3"
                                            height="48"
                                    />
                                    <div
                                            v-if="!data.item.image"
                                            class="avatar-xs d-inline-block mr-2"
                                    >

                                    </div>

                                </template>
                                <template #cell(name)="data">
                                    <span class="font-15 font-weight-bold text-dark"> {{ data.item.name}}</span>
                                </template>
                                <template #cell(costPrice)="data">
                                    <span class="badge badge-outline-info p-1"> {{ data.item.costPrice}}</span>
                                </template>
                                <template #cell(sellPrice)="data">
                                    <span class="badge badge-outline-success p-1"> {{ data.item.sellPrice}}</span>
                                </template>
                                <template #cell(quantity)="data">
                                    <span class="badge badge-outline-danger p-1"> {{ data.item.quantity}}</span>
                                </template>
                                <template #cell(dateCreated)="data">

                                    <span class="font-12 font-weight-bold text-dark"> {{ data.item.dateCreated | moment("MMMM Do YYYY") }}</span>
                                </template>
                                <template #cell(actions)="data">
                                    <router-link class="btn btn-primary mr-2" style="cursor: pointer" :to="'/grocery/'+data.item.id"> <i class="mdi mdi-eye mx-1"></i>View</router-link>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Layout>
</template>

<script>
    import PageHeader from "@/components/Page-header";
    import LottieLoader from "../../../components/LottieLoader";
    import Layout from "../../layouts/main";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import SweetAlertConfig from "../../../../SwalConfig";
    import SweetAlertWrapper from "../../../../sweetAlert";
    export default {
        name: "ManageGroceries",
        components : {
            PageHeader,
            LottieLoader,
            Layout
        },
        data() {
            return {
                showLoader : false,
                title: "Manage groceries",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "groceries",
                        active: true,
                    },
                ],
                listOfGroceries :[],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",
                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"Grocery name",
                        key: "name",
                        sortable: true,
                    },
                    {
                        label:"Grocery image",
                        key: "image",
                        sortable: true,
                    },
                    {
                        label:"Cost price",
                        key: "costPrice",
                        sortable: true,
                    },
                    {
                        label:"Selling price",
                        key: "sellPrice",
                        sortable: true,
                    },
                    {
                        label:"Quantity",
                        key: "quantity",
                        sortable: true,
                    },
                    {
                        label:"Date added",
                        key: "dateCreated",
                        sortable: true,
                    },
                    {
                        key: "actions",
                        sortable: true,
                    },
                ],
            }
        },
        methods : {
            getProductsBYGroceries() {

                this.$validator.validateAll().then((result) => {
                    if (result) {

                        this.showLoader = true;
                        BaseUrl.AxiosBearer.get(`/store/products/get-products-by-classification?classification=GROCERIES&pageSize=1000000`).then((resp) => {
                            this.showLoader = false
                            if(resp) {
                                this.listOfGroceries = []


                                resp.data.payload.forEach((data) => {
                                    this.listOfGroceries.push({
                                        id : data.id,
                                        name : data?.productName,
                                        image : data?.mainImage?.imageUrl,
                                        costPrice :"GHS "+ BaseUrl.formatMoney(data.costPrice,'GHS'),
                                        sellPrice : "GHS " +BaseUrl.formatMoney(data.sellingPrice,'GHS'),
                                        dateCreated : data.createdAt,
                                        quantity : data.quantity
                                    })
                                })
                            }

                        }).catch((error) => {
                            this.$bvToast.toast(error, ToastConfigAlert)
                        })

                    }
                })



            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        created() {
            this.getProductsBYGroceries()
        },
        computed: {
            rows() {
                return this.listOfGroceries.length;
            },
        }
    }
</script>

<style scoped>

</style>